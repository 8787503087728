import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import Navigation from './components/Navigation';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import ComingSoon from './pages/ComingSoon';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Footer from './components/Footer';
import ResetScroll from './components/ResetScroll';
import Accessibility from './pages/Accessibility';

const App = () => {
  return (
    <Router>
      <ResetScroll />
      <div className="screen-reader-only" aria-label="Skip to section">
        <a href="#navigation" className="skip-link">Skip to navigation</a>
        <a href="#content" className="skip-link">Skip to main content</a>
        <a href="#footer" className="skip-link">Skip to footer</a>
      </div>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/services" element={<ComingSoon />}/>
        <Route path="/resources" element={<ComingSoon />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/terms" element={<Terms />}/>
        <Route path="/privacy" element={<Privacy />}/>
        <Route path="/accessibility" element={<Accessibility />}/>
      </Routes>
      <Footer />
      <ScrollToTop smooth color="#000000" />
    </Router>
  );
}

export default App;
