import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Logo} from './icons/Logos';

const colorDark = "#2D2D2D";

const Navigation = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="container-nav" id="navigation" aria-expanded={click ? "true" : "false"}>
      <NavLink to='/' tabIndex="0" className="nav-left-col">
        <Logo color={colorDark} />
      </NavLink>
      <ul className={click ? "nav-centre-col nav-list mobile-menu-shown" : "nav-centre-col nav-list mobile-menu-hidden"}>
        <li onClick={closeMobileMenu}><NavLink to="/">Home</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/about">About</NavLink></li>
        {/* <li onClick={closeMobileMenu}><NavLink to="/services">Services</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/resources">Resources</NavLink></li> */}
        <li onClick={closeMobileMenu}><NavLink to="/contact">Contact</NavLink></li>
      </ul>
      <div className="nav-right-col">
        <NavLink to="/contact" className="button">Get Started</NavLink>
      </div>
      <button aria-controls="menu" onClick={handleClick} className={click ? "mobile-menu-indicator icon-menu mobile-menu-opened" : "mobile-menu-indicator icon-menu"} role="navigation">
        <p>Menu</p>
        <span />
        <span />
        <span />
      </button>
    </nav>
  );
}

export default Navigation;
