import React, {Fragment} from 'react';
import {IconOpenLink} from './icons/Icons';

const CTAButton = () => {
  return (
    <a href="https://calendly.com/helloaccessible/discovery" target="_blank" className="button link-new-tab" aria-label="Link opens in a new tab">
        Book a discovery call <IconOpenLink />
    </a>
  );
}

export default CTAButton;
