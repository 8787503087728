import React from 'react';
import {NavLink} from 'react-router-dom';
import {IconOpenLink} from './icons/Icons';
import {LogoEst} from './icons/Logos';

const FooterNew = () => {
    return (
        <footer id="footer">
            <div />
            <div className="new-footer-left">
                <div className="new-footer-top">
                    <h5><NavLink to="/">Home</NavLink></h5>
                    <h5><NavLink to="/about">About</NavLink></h5>
                    <h5><NavLink to="/contact">Contact</NavLink></h5>
                </div>
                <hr />
                <div className="new-footer-bottom">
                    <h5>© All rights reserved</h5>
                </div>
            </div>
            <div className="new-footer-middle">
                <LogoEst />
            </div>
            <div className="new-footer-right">
                <div className="new-footer-top">
                    <h5><NavLink to="/privacy">Privacy</NavLink></h5>
                    <h5><NavLink to="/terms">Terms</NavLink></h5>
                    <h5><NavLink to="/accessibility">Accessibility</NavLink></h5>
                </div>
                <hr />
                <div className="new-footer-bottom">
                    <h5><a href="mailto:support@helloaccessible.com" target="_blank" aria-label="Link opens in a new tab" className="link-new-tab">Email <IconOpenLink /></a></h5>
                    <h5><a href="https://www.linkedin.com/showcase/helloaccessible" target="_blank" aria-label="Link opens in a new tab" className="link-new-tab">LinkedIn <IconOpenLink /></a></h5>
                    <h5><a href="https://www.instagram.com/helloaccessible" target="_blank" aria-label="Link opens in a new tab" className="link-new-tab">Instagram <IconOpenLink /></a></h5>
                </div>
            </div>
            <div />
        </footer>
    );
}

const Footer = () => <FooterNew />;

export default Footer;