import React, {Fragment} from 'react';
import {IconOpenLink} from '../components/icons/Icons'; 
 
const About = () => {
  return (
    <Fragment>
      <section className="about-page">
        <h2>About Us</h2>
        <div className="about-header">
          <div>
            <h3>Who are we?</h3>
            <p>Hello Accessible is a leading provider of all things accessibility, dedicated to empowering individuals and organisations to create inclusive digital experiences. </p>
          </div>
          <div>
            <h3>What do we do?</h3>
            <p>Our mission is to bridge the gap between technology and inclusivity, enabling companies, regardless of their abilities, to access and engage with digital content.</p>
          </div>
          <div>
            <h3>How do we do it?</h3>
            <p>We specialise in helping organisations navigate the complexities of accessibility compliance and implementation through a range of holistic services, including digital accessibility audits, remediation services, customised training programs, and VPAT document preparation to help organisations comply with WCAG standards and accessibility laws.</p>
          </div>
          <div>
            <h3>Why do we do it?</h3>
            <p>Hello Accessible is founded on the belief that accessibility is not only a legal requirement but also a moral imperative and a significant business opportunity. By making digital spaces accessible, we unlock value for our clients, enabling them to reach a broader audience and foster greater customer loyalty, and ensure that all customers can access the same content and services for a more equitable experience.</p>
          </div>
          <div>
            <h3>Why you?</h3>
            <p>We differentiate ourselves from competitors by combining technical expertise with a focus on practical, actionable solutions, ensuring that accessibility improvements are realistic, sustainable, and effective.</p>
          </div>
        </div>
        <br /><br />
        <h2>Team</h2>
        <div className="about-team">
          <div className="about-team-content">
            <div>
              <div className="about-team-photos">
                <img src="../../../assets/founder.jpeg" alt="Photo of Hello Accessibility's founder" />
              </div>
              <div className="about-team-name">
                <h4>Patricia Goh</h4>
                <h6><i>(she/her)</i></h6>
              </div>
              <h5>Founder</h5>
              <br />
              <p>With over 8 years of global experience in software engineering and leadership, I specialize in building high-performing teams and driving strategic initiatives. As the founder of Hello Accessible, I am passionate about digital accessibility and dedicated to helping organizations create inclusive, user-friendly digital experiences. My mission is to bridge the gap between technology and inclusivity, ensuring that all individuals, regardless of ability, can engage with digital content seamlessly.</p>
            </div>
            <div>
              <div className="about-team-photos" />
              <div className="about-team-name">
                <h4>This could be you</h4>
              </div>
              <h5>Your Title</h5>
              <br />
              <p>We're always looking for talented professionals to join us. Please reach out to us with your resumé and let's talk!</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default About;
